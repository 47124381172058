<template> </template>

<script>
import { userLogout } from '@/auth/utils';

export default {
  mounted() {
    userLogout();

    // Redirect to login page
    this.$router.push({ name: 'login' });
  },
};
</script>
